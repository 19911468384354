
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient, FabricEstimationClient } from '@/services/Services';
import LifeCycles from './components/lifeCycles.vue';
import * as OM from '@/Model';

@Options({
    components: {
        LifeCycles
    }
})
export default class Farming extends Vue {

    estimationIdentifier: string = "";

    materials: string[] = [];
    countries: OM.TextIdentifier[] = [];
    originCountry: OM.ElectricityEmission = null;
    activeSection: string = "";

    model: OM.FabricFarmingVM = new OM.FabricFarmingVM();

    loaded: boolean = false;

    created() {
        this.estimationIdentifier = this.$route.params.estimationIdentifier.toString();

        Promise.all([
            FabricEstimationClient.getFarmingDefaultMaterials(),
            ElectricityEmissionClient.getCountries(true),
            FabricEstimationClient.getFarmingInfo(this.estimationIdentifier),
            FabricEstimationClient.getCountryOfOrigin(this.estimationIdentifier),
        ])
        .then(xs => {
            this.materials = xs[0];
            this.countries = xs[1];

            this.model.estimationIdentifier = this.estimationIdentifier;
            this.model.farmingInfo = xs[2];

            var materialKeys = Object.keys(this.model.farmingInfo);
            if(!materialKeys || materialKeys.length == 0)
            {
                this.materials.forEach(element => {
                    this.model.farmingInfo[element] = new OM.FarmingInfo();
                });
            }

            this.originCountry = xs[3];
            this.activeSection = this.materials[0];

            this.$nextTick(() => {
                this.loaded = true;
            })
        })
    }

    get isDisabled() {  
        var materialKeys = Object.keys(this.model.farmingInfo);
        var countOk = 0;

        materialKeys.forEach(key => {
            if(this.model.farmingInfo[key].ha > 0 && this.model.farmingInfo[key].landPreparation_FarmingCountry.identifier)
                countOk++;
        });

        return countOk == 0;
    }

    updateFarmingInfo() {
        FabricEstimationClient.updateFarmingInfo(this.model)
        .then(x => {
            FabricEstimationClient.checkIfHasWool(this.estimationIdentifier)
            .then(x => {
                if(x)
                    this.$router.push('/fabric-calculator/wool/' + this.estimationIdentifier);
                else
                    this.$router.push("/fabric-calculator/rawmaterials/" + this.estimationIdentifier);
            })
        })
    }

}
