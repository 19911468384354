
import { Prop } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import { ElectricityEmissionClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class LifeCycles extends Vue {

    @Prop({
        default: []
    }) countries: OM.TextIdentifier[];

    @Prop({
        default: null
    }) originCountry: OM.ElectricityEmission;

    @Prop({
        default: () => new OM.FarmingInfo()
    }) model: OM.FarmingInfo;

    countryEmissionFactor: number = 0;

    created() {
        if(this.originCountry)
            this.model.landPreparation_FarmingCountry = this.originCountry;
    }

    //land preparations

    get getLandPreparationDieselKgCO2eHa() {
        if(!this.model.landPreparation_DieselLitersHa)
            return 0;

        var ris = 4.0318087 * this.model.landPreparation_DieselLitersHa;
        return ris;
    }
    get getLandPreparationDieselKgCO2e() {
        if(!this.model.ha || this.getLandPreparationDieselKgCO2eHa == 0)
            return 0;

        var ris = this.model.ha * this.getLandPreparationDieselKgCO2eHa;
        return ris;
    }

    getCountryEmissionFactor() {
        if(!this.model.landPreparation_FarmingCountry || !this.model.landPreparation_FarmingCountry.identifier)
            return;

        ElectricityEmissionClient.getCountryEmissionFactor(this.model.landPreparation_FarmingCountry.identifier, null)
        .then(x => {
            this.countryEmissionFactor = x;
        })
    }
    get getLandPreparationElectricKgCO2e() {
        if(!this.model.ha || !this.model.landPreparation_FarmingCountry || !this.model.landPreparation_ElectricKwhHa || !this.countryEmissionFactor)
            return 0;

        var ris = this.model.landPreparation_ElectricKwhHa * this.model.ha * this.countryEmissionFactor;
        return ris;
    }

    recalculateElectricPercentage() {
        this.model.landPreparation_ElectricPercentage = 100 - this.model.landPreparation_ConventionalPercentage;
    }
    recalculateConventionalPercentage() {
        this.model.landPreparation_ConventionalPercentage = 100 - this.model.landPreparation_ElectricPercentage;
    }

    get getLandPreparationFieldLevellingKgCO2e() {
        if(!this.getLandPreparationDieselKgCO2e || !this.getLandPreparationElectricKgCO2e)
            return 0;

        var ris = ((this.model.landPreparation_ConventionalPercentage / 100) * this.getLandPreparationDieselKgCO2e) + ((this.model.landPreparation_ElectricPercentage / 100) * this.getLandPreparationElectricKgCO2e);
        return ris;
    }
    get getLandPreparationFieldLevellingKgCO2eHa() {
        if(!this.model.ha || !this.getLandPreparationFieldLevellingKgCO2e)
            return 0;

        var ris = this.getLandPreparationFieldLevellingKgCO2e / this.model.ha;
        return ris;
    }

    //fertilizers

    get getFertilizerCompostKgCO2eHa() {
        if(!this.getFertilizerCompostKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getFertilizerCompostKgCO2e / this.model.ha;
        return ris;
    }
    get getFertilizerCompostKgCO2e() {
        if(!this.model.ha || !this.model.fertilizer_CompostKgHa)
            return 0;

        var ris = this.model.ha * this.model.fertilizer_CompostKgHa * 0.08242;
        return ris;
    }

    get getFertilizerCowDungKgCO2eHa() {
        if(!this.getFertilizerCowDungKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getFertilizerCowDungKgCO2e / this.model.ha;
        return ris;
    }
    get getFertilizerCowDungKgCO2e() {
        if(!this.model.ha || !this.model.fertilizer_CowDungKgHa)
            return 0;

        var ris = this.model.ha * this.model.fertilizer_CowDungKgHa * 0.535;
        return ris;
    }

    get getFertilizerYardManureKgCO2eHa() {
        if(!this.getFertilizerYardManureKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getFertilizerYardManureKgCO2e / this.model.ha;
        return ris;
    }
    get getFertilizerYardManureKgCO2e() {
        if(!this.model.ha || !this.model.fertilizer_FarmYardManureKgHa)
            return 0;

        var ris = this.model.ha * this.model.fertilizer_FarmYardManureKgHa * 0.0322;
        return ris;
    }

    get getFertilizerUreaKgCO2eHa() {
        if(!this.getFertilizerUreaKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getFertilizerUreaKgCO2e / this.model.ha;
        return ris;
    }
    get getFertilizerUreaKgCO2e() {
        if(!this.model.ha || !this.model.fertilizer_UreaKgHa)
            return 0;

        var ris = this.model.ha * this.model.fertilizer_UreaKgHa * 4.106;
        return ris;
    }

    //inputs

    get getInputSeedKgCO2eHa() {
        if(!this.getInputSeedKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getInputSeedKgCO2e / this.model.ha;
        return ris;
    }
    get getInputSeedKgCO2e() {
        if(!this.model.ha || !this.model.input_SeedKgHa)
            return 0;

        var ris = this.model.ha * this.model.input_SeedKgHa * 1.31725;
        return ris;
    }

    get getInputDAPKgCO2eHa() {
        if(!this.getInputDAPKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getInputDAPKgCO2e / this.model.ha;
        return ris;
    }
    get getInputDAPKgCO2e() {
        if(!this.model.ha || !this.model.input_DAPKgHa)
            return 0;

        var ris = this.model.ha * this.model.input_DAPKgHa * 7.2455;
        return ris;
    }

    get getInputPotashKgCO2eHa() {
        if(!this.getInputPotashKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getInputPotashKgCO2e / this.model.ha;
        return ris;
    }
    get getInputPotashKgCO2e() {
        if(!this.model.ha || !this.model.input_PotashKgHa)
            return 0;

        var ris = this.model.ha * this.model.input_PotashKgHa * 0.4089;
        return ris;
    }

    get getInputIrrigationKgCO2eHa() {
        if(!this.getInputIrrigationKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getInputIrrigationKgCO2e / this.model.ha;
        return ris;
    }
    get getInputIrrigationKgCO2e() {
        if(!this.model.ha || !this.model.input_IrrigationM3Ha)
            return 0;

        var ris = this.model.ha * this.model.input_IrrigationM3Ha * 0.136;
        return ris;
    }

    //pesticide

    get getPesticideImidaclopridKgCO2eHa() {
        if(!this.getPesticideImidaclopridKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getPesticideImidaclopridKgCO2e / this.model.ha;
        return ris;
    }
    get getPesticideImidaclopridKgCO2e() {
        if(!this.model.ha || !this.model.pesticide_ImidaclopridKgHa)
            return 0;

        var ris = this.model.ha * this.model.pesticide_ImidaclopridKgHa * 2.872;
        return ris;
    }

    get getPesticideMonocrotophosKgCO2eHa() {
        if(!this.getPesticideMonocrotophosKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getPesticideMonocrotophosKgCO2e / this.model.ha;
        return ris;
    }
    get getPesticideMonocrotophosKgCO2e() {
        if(!this.model.ha || !this.model.pesticide_MonocrotophosKgHa)
            return 0;

        var ris = this.model.ha * this.model.pesticide_MonocrotophosKgHa * 2.888;
        return ris;
    }

    get getPesticideAcephateKgCO2eHa() {
        if(!this.getPesticideAcephateKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getPesticideAcephateKgCO2e / this.model.ha;
        return ris;
    }
    get getPesticideAcephateKgCO2e() {
        if(!this.model.ha || !this.model.pesticide_AcephateKgHa)
            return 0;

        var ris = this.model.ha * this.model.pesticide_AcephateKgHa * 1.879;
        return ris;
    }

    get getPesticideProfenofosKgCO2eHa() {
        if(!this.getPesticideProfenofosKgCO2e || !this.model.ha)
            return 0;

        var ris = this.getPesticideProfenofosKgCO2e / this.model.ha;
        return ris;
    }
    get getPesticideProfenofosKgCO2e() {
        if(!this.model.ha || !this.model.pesticide_ProfenofosKgHa)
            return 0;

        var ris = this.model.ha * this.model.pesticide_ProfenofosKgHa * 1.63;
        return ris;
    }

    //yield

    get getYieldHarvesting() {
        if(!this.model.ha)
            return 0;

        return this.model.ha * 503.995;
    }
    get getYieldCottonSeedKgCO2eHa() {
        if(!this.getYieldCottonSeedKgCO2e || !this.model.ha)
            return 0;

        return this.getYieldCottonSeedKgCO2e / this.model.ha;   
    }
    get getYieldCottonSeedKgCO2e() {
        var pesticideTotal = this.getPesticideImidaclopridKgCO2e + this.getPesticideMonocrotophosKgCO2e + this.getPesticideAcephateKgCO2e + this.getPesticideProfenofosKgCO2e;
        var inputTotal = this.getInputSeedKgCO2e + this.getInputDAPKgCO2e + this.getInputPotashKgCO2e + this.getInputIrrigationKgCO2e;
        var fertilizerTotal = this.getFertilizerCompostKgCO2e + this.getFertilizerCowDungKgCO2e + this.getFertilizerYardManureKgCO2e + this.getFertilizerUreaKgCO2e;
        return pesticideTotal + inputTotal + fertilizerTotal + this.getLandPreparationFieldLevellingKgCO2e + this.getYieldHarvesting;
    }
    get getCottonEmissionFactor() {
        if(!this.getYieldCottonSeedKgCO2eHa || !this.model.yield_CottonSeedProduction)
            return 0;

        return this.getYieldCottonSeedKgCO2eHa / this.model.yield_CottonSeedProduction;
    }
}
