import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-92d455e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex align-items-center justify-content-between mb-4 pb-2" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "d-flex justify-content-end mt-3" }
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_life_cycles = _resolveComponent("life-cycles")!
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.materials, (material) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["materialTab", {'active': _ctx.activeSection == material}]),
              onClick: ($event: any) => (_ctx.activeSection = material),
              key: material
            }, _toDisplayString(material), 11, _hoisted_3))
          }), 128))
        ]),
        _createVNode(_component_life_cycles, {
          model: _ctx.model.farmingInfo[_ctx.activeSection],
          countries: _ctx.countries,
          originCountry: _ctx.originCountry
        }, null, 8, ["model", "countries", "originCountry"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "btn btn-tertiary",
            disabled: _ctx.isDisabled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateFarmingInfo()))
          }, [
            _createVNode(_component_localized_text, {
              localizedKey: "Continue",
              text: "Continue"
            })
          ], 8, _hoisted_5)
        ])
      ]))
    : _createCommentVNode("", true)
}